import React from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  Filter,
  ImageField,
  List,
  TextField,
  TextInput,
} from 'react-admin';

const ChatFilter = props => (
  <Filter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
  </Filter>
);

export const ChatLists = props => (
  <List filters={<ChatFilter />} {...props}>
    <Datagrid rowClick='edit'>
      <TextField source='userID' label='유저 ID' />
      <TextField source='chatRoomId' label='채팅방 ID' />
      <TextField source='text' label='메시지' />
      <ImageField source='imageUrl' label='이미지' />
      <BooleanField source='isRead' label='읽음 여부' />
      <TextField source='nickname' label='닉네임' />
      <DateField source='createdAt' label='보낸 시각' showTime />
    </Datagrid>
  </List>
);
