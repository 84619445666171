import { initializeApp } from 'firebase/app';
import { collection, getDocs, getFirestore, query } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyDCMNuxqGJ2iCxrjthykK8NIA3yyzpcXRY',
  authDomain: 'motive-1b072.firebaseapp.com',
  projectId: 'motive-1b072',
  storageBucket: 'motive-1b072.appspot.com',
  messagingSenderId: '261174620824',
  appId: '1:261174620824:web:1ad4bc875a3f2a55f896ed',
  measurementId: 'G-L6G9H2BQJL',
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const dataProvider = {
  getList: async (resource, params) => {
    // Firestore 컬렉션에서 문서들을 조회
    const collectionRef = collection(db, resource);
    const q = query(collectionRef);
    const querySnapshot = await getDocs(q);

    const data = querySnapshot.docs.map(doc => {
      console.log(doc);

      return {
        ...doc.data(),
        id: doc.id,
        createdAt: doc.data().createdAt?.toDate(),
        lastMessageTime: doc.data().lastMessageTime?.toDate(),
        timestamp: doc.data().timestamp?.toDate(),
      };
    });

    console.log(data);

    return {
      data,
      total: data.length,
    };
  },
};

export default dataProvider;
