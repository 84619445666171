import { Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useLogin, useNotify } from 'react-admin';
import { useNavigate } from 'react-router-dom';

const AdminLoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const notify = useNotify();
  const login = useLogin();
  const navigate = useNavigate();

  const handleSubmit = e => {
    e.preventDefault();
    login({ username, password })
      .then(() => {
        navigate('/admin');
      })
      .catch(() => {
        notify('Invalid username or password', 'warning');
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        name='username'
        label='Username'
        value={username}
        onChange={e => setUsername(e.target.value)}
      />
      <TextField
        name='password'
        label='Password'
        type='password'
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <Button type='submit' color='primary' variant='contained'>
        Login
      </Button>
    </form>
  );
};

export default AdminLoginPage;
