import React from 'react';
import apple from '../../assets/images/apple.png';
import google from '../../assets/images/google.png';

const StoreButton = () => {
  const handleGoogleBtnClick = () => {
    window.open('https://play.google.com/store/apps/details?id=com.ap.motive&hl=ko', '_blank');
  };
  const handleAppleBtnClick = () => {
    window.open('https://apps.apple.com/kr/app/%EB%AA%A8%ED%8B%B0%EB%B8%8C-%EB%82%B4-%EA%B7%BC%EC%B2%98-%EC%9A%B4%EB%8F%99%ED%8C%8C%ED%8A%B8%EB%84%88-%EC%B0%BE%EA%B8%B0/id6497334419', '_blank');
  };

  return (
    <div className='storeBtn'>
      <img
        src={google}
        alt='구글'
        className='googleBtn'
        onClick={handleGoogleBtnClick}
      />
      <img
        src={apple}
        alt='애플'
        className='googleBtn'
        onClick={handleAppleBtnClick}
      />
    </div>
  );
};

export default StoreButton;
