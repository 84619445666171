import React from 'react';
import {
  Datagrid,
  DateField,
  Filter,
  List,
  TextField,
  TextInput,
} from 'react-admin';
import UserField from '../../components/Admin/UserField';

const ChatFilter = props => (
  <Filter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
  </Filter>
);

const ChatRoomLists = props => (
  <List filters={<ChatFilter />} {...props}>
    <Datagrid rowClick='edit'>
      <TextField source='lastMessage' label='마지막 메시지' />
      <DateField
        source='lastMessageTime'
        label='마지막 메시지 보낸 시각'
        showTime
      />
      <UserField source='users' label='사용자 ID' />
    </Datagrid>
  </List>
);

export default ChatRoomLists;
