import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material";
import React, { useState } from "react";

const menuItems = ["홈", "모티브 소개", "모티브 소식", "채용공고"];

const Nav = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{ backgroundColor: "#f1f3f6" }}
        elevation={0}
      >
        <Toolbar
          sx={{
            maxWidth: {
              xs: "100%", // 모바일 화면
              sm: "100%", // 작은 화면
              md: "1023px", // 중간 화면
              lg: "1075px", // 큰 화면
            },
            width: "100%",
            margin: "0 auto",
          }}
        >
          {isMobile ? (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <Typography
              variant="h6"
              style={{
                flexGrow: 1,
                fontWeight: "bold",
                color: "black",
                fontSize: "16px",
              }}
            >
              MOTIVE
            </Typography>
          )}
          {!isMobile &&
            menuItems.map((text, index) => (
              <Typography
                key={index}
                style={{
                  marginRight: 50, // 메뉴 항목 간의 간격을 넓히기 위해 marginRight를 50px로 설정
                  cursor: "pointer",
                  color: "black",
                  fontSize: "16px",
                }}
              >
                {text}
              </Typography>
            ))}
        </Toolbar>
      </AppBar>
      <Drawer anchor="top" open={drawerOpen} onClose={toggleDrawer}>
        <Box
          role="presentation"
          sx={{
            maxWidth: {
              xs: "100%", // 모바일 화면
              sm: "100%", // 작은 화면
              md: "1023px", // 중간 화면
              lg: "1075px", // 큰 화면
            },
            width: "100%",
            margin: "0 auto",
          }}
        >
          <List>
            {menuItems.map((text) => (
              <ListItemButton key={text}>
                <ListItemText
                  primary={text}
                  primaryTypographyProps={{
                    style: { color: "black", fontSize: "16px" },
                  }}
                />
              </ListItemButton>
            ))}
          </List>
        </Box>
      </Drawer>
      <Toolbar /> {/* 고정된 AppBar 높이를 보정하기 위해 추가 */}
    </>
  );
};

export default Nav;
