// InstagramForm.js
import SendIcon from '@mui/icons-material/Send';
import { Button, TextField } from '@mui/material';
import React from 'react';

const InstagramForm = ({
  inputRef,
  instagramId,
  setInstagramId,
  isInvalid,
  setIsInvalid,
  onSubmit,
}) => {
  const handleInputChange = e => {
    const value = e.target.value.replace(/\s/g, '');
    setIsInvalid(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(value));
    setInstagramId(value);
  };

  const handleFormSubmit = e => {
    e.preventDefault();
    if (!instagramId.trim() || isInvalid) {
      alert('올바른 인스타그램 아이디를 입력해주세요.');
    } else {
      onSubmit();
    }
  };

  return (
    <div className='instaId'>
      <form onSubmit={handleFormSubmit} className='formContainer'>
        <TextField
          label='인스타그램 아이디'
          id='instagramId'
          value={instagramId}
          onChange={handleInputChange}
          error={isInvalid}
          helperText={
            isInvalid ? '올바른 인스타그램 아이디를 입력해주세요.' : ''
          }
          inputRef={inputRef}
        />
        <Button
          type='submit'
          variant='contained'
          color='primary'
          endIcon={<SendIcon />}
        />
      </form>
    </div>
  );
};

export default InstagramForm;
