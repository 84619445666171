import { ViewList } from '@mui/icons-material';
import * as React from 'react';
import { DashboardMenuItem, MenuItemLink } from 'react-admin';

export const MyMenu = ({ onMenuClick }) => {
  return (
    <div>
      <DashboardMenuItem to='/admin' onClick={onMenuClick} />
      <MenuItemLink
        to='/admin/users'
        primaryText='Users'
        leftIcon={<ViewList />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to='/admin/photos'
        primaryText='User Photos'
        leftIcon={<ViewList />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to='/admin/chat'
        primaryText='Chats'
        leftIcon={<ViewList />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to='/admin/chatRooms'
        primaryText='Chat Rooms'
        leftIcon={<ViewList />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to='/admin/pre-applies'
        primaryText='Pre Applies'
        leftIcon={<ViewList />}
        onClick={onMenuClick}
      />
    </div>
  );
};
