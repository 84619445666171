import React from 'react';
import { Datagrid, ImageField, List, TextField } from 'react-admin';

const UserLists = props => {
  return (
    <List {...props}>
      <Datagrid rowClick='edit'>
        <TextField source='id' label='ID' />
        <TextField source='nickname' label='닉네임' />
        <TextField source='activityArea' label='지역' />
        <TextField source='exerciseDuration' label='경력' />
        <TextField source='gender' label='성별' />
        <TextField source='age' label='나이' />
        <TextField source='introduction' label='자기소개' />
        <ImageField source='imageUrl' label='피드 사진' />
      </Datagrid>
    </List>
  );
};

export default UserLists;
