import React, { useEffect, useRef, useState } from 'react';
import iPhoneImage from '../../assets/images/iphone2.png';
import qr from '../../assets/images/qr2.png';
import InstagramForm from '../../components/InstagramForm/InstagramForm';
import MainCopy from '../../components/MainCopy/MainCopy';
import Nav from '../../components/Nav/Nav';
import StoreButton from '../../components/StoreButton/StoreButton';
import postInstagramId from '../../services/instagramService';
import './Main.scss';
import {useMediaQuery, useTheme} from "@mui/material";

const Main = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [instagramId, setInstagramId] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [submitRequest, setSubmitRequest] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const submitInstagrmId = async () => {
      if (submitRequest) {
        try {
          await postInstagramId(instagramId);
          alert('아이디 등록 성공! 출시 이후 가장 먼저 알려드릴게요!');
        } catch (error) {
          alert(error.message);
        } finally {
          setSubmitRequest(false);
        }
      }
    };
    submitInstagrmId();
  }, [submitRequest, instagramId]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleGoogleBtnClick = () => {
    if (isMobile) {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        window.open('https://apps.apple.com/kr/app/%EB%AA%A8%ED%8B%B0%EB%B8%8C-%EB%82%B4-%EA%B7%BC%EC%B2%98-%EC%9A%B4%EB%8F%99%ED%8C%8C%ED%8A%B8%EB%84%88-%EC%B0%BE%EA%B8%B0/id6497334419', '_blank');
      } else if (/android/i.test(userAgent)) {
        window.open('https://play.google.com/store/apps/details?id=com.ap.motive&hl=ko', '_blank');
      } else {
        alert('모바일 기기에서만 다운로드 가능합니다.');
      }
    } else {
      alert('앱 준비중입니다');
    }
  };

  const handleSubmit = () => {
    setSubmitRequest(true);
  };

  const isMobileOrTablet = width <= 1024;

  return (
    <>
      <Nav mainRef={inputRef}></Nav>
      <div className='body'>
        <div className='main'>
          {isMobileOrTablet && (
            <div className='mainCopy'>
              <span>함께 운동할 친구,</span>
              <br />
              <span>모티브에서 찾아요!</span>
            </div>
          )}
          <div className='mainRight'>
            <div className='iphone'>
              <img src={iPhoneImage} alt='아이폰' />
            </div>
          </div>
          <div className='mainLeft'>
            {!isMobileOrTablet && <MainCopy />}
            <div className='cta'>
              <StoreButton />
              <img src={qr} alt='큐알' className='qr' />
            </div>
          </div>
        </div>
      </div>
      <div className='instaCopy'>
        <span>모티브를 가장 먼저 만나보세요</span>
      </div>
      <div className='instaSubCopy'>
        <span>인스타그램 아이디를 알려주시면,</span>
        <br />
        <span>모티브를 가장 먼저 만날 수 있어요!</span>
      </div>
      <InstagramForm
        inputRef={inputRef}
        instagramId={instagramId}
        setInstagramId={setInstagramId}
        isInvalid={isInvalid}
        setIsInvalid={setIsInvalid}
        onSubmit={handleSubmit}
      />
      <div className='appBtn' onClick={handleGoogleBtnClick}>
        모티브 시작하기
      </div>
    </>
  );
};

export default Main;
