import React, { useEffect, useRef } from "react";
import "./LandingPage.scss";
import featureImage1 from "../../assets/images/feature1.png";
import featureImage2 from "../../assets/images/feature2.png";
import featureImage3 from "../../assets/images/feature3.png";
import featureImage4 from "../../assets/images/feature4.png";
import Nav from "../../components/Nav/Nav";

const features = [
  {
    id: 1,
    image: featureImage1,
    title: "운동 파트너 찾기",
    headings: ["나와 취향 꼭 맞는", "운동 파트너"],
    descriptions: [
      "웨이트 트레이닝부터 러닝까지 ",
      "모든 운동, 같이할 파트너 모티브에서 만나요!",
    ],
    buttonText: "바로 가기 >",
  },
  {
    id: 2,
    image: featureImage2,
    title: "운동 모임",
    headings: ["어떤 운동을", "좋아하세요?"],
    descriptions: [
      "웨이트 트레이닝부터 러닝까지 ",
      "모든 운동, 같이할 파트너 모티브에서 만나요!",
    ],
    buttonText: "바로 가기 >",
  },
  {
    id: 3,
    image: featureImage3,
    title: "킹주성맨",
    headings: ["혼자 운동하기", "싫을 때,"],
    descriptions: [
      "웨이트 트레이닝부터 러닝까지 ",
      "모든 운동, 같이할 파트너 모티브에서 만나요!",
    ],
    buttonText: "바로 가기 >",
  },
  {
    id: 4,
    image: featureImage4,
    title: "건강 관리 팁",
    headings: ["매일매일", "동기부여맨"],
    descriptions: [
      "웨이트 트레이닝부터 러닝까지 ",
      "모든 운동, 같이할 파트너 모티브에서 만나요!",
    ],
    buttonText: "바로 가기 >",
  },
];

const LandingPage = () => {
  const sectionRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target
              .querySelector(".feature-content")
              .classList.add("visible");
            observer.unobserve(entry.target); // Once visible, stop observing
          }
        });
      },
      { threshold: 0.9 }
    );

    const currentRefs = sectionRefs.current;
    currentRefs.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      currentRefs.forEach((section) => {
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  }, []);

  return (
    <div className="landing-page">
      <Nav />

      <div className="toptop"></div>
      {features.map((feature, index) => (
        <section
          key={feature.id}
          className={`feature-section ${index % 2 === 0 ? "left" : "right"}`}
          ref={(el) => (sectionRefs.current[index] = el)}
        >
          <img
            src={feature.image}
            alt={`Feature ${feature.id}`}
            className="feature-image"
          />
          <div className="feature-content">
            <h3 className="feature-title">{feature.title}</h3>
            {feature.headings.map((heading, idx) => (
              <h2 key={idx} className="feature-heading">
                {heading}
              </h2>
            ))}
            {feature.descriptions.map((description, idx) => (
              <p key={idx} className="feature-description">
                {description}
              </p>
            ))}
            <button className="feature-button">{feature.buttonText}</button>
          </div>
        </section>
      ))}
    </div>
  );
};

export default LandingPage;
