import React from 'react';
import { useRecordContext } from 'react-admin';

const UserField = ({ source }) => {
  const record = useRecordContext();
  if (!record) return null;
  const users = record[source];
  return <span>{users.join(', ')}</span>; // 사용자 ID를 쉼표로 구분된 문자열로 변환
};

export default UserField;
