import React from 'react';
import {
  Datagrid,
  DateField,
  Filter,
  ImageField,
  List,
  TextField,
  TextInput,
} from 'react-admin';

const UserPhotoFilter = props => (
  <Filter {...props}>
    <TextInput label='Search' source='q' alwaysOn />
  </Filter>
);

const UserPhotoLists = props => (
  <List filters={<UserPhotoFilter />} {...props}>
    <Datagrid rowClick='edit'>
      <TextField source='userId' label='유저 ID' />
      <ImageField source='photoLink' label='피드 이미지' />
      <DateField source='timestamp' label='업로드 시각' showTime />
    </Datagrid>
  </List>
);

export default UserPhotoLists;
