import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDCMNuxqGJ2iCxrjthykK8NIA3yyzpcXRY',
  authDomain: 'motive-1b072.firebaseapp.com',
  projectId: 'motive-1b072',
  storageBucket: 'motive-1b072.appspot.com',
  messagingSenderId: '261174620824',
  appId: '1:261174620824:web:1ad4bc875a3f2a55f896ed',
  measurementId: 'G-L6G9H2BQJL',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const authProvider = {
  login: async ({ username, password }) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        username,
        password
      );
      const accessToken = userCredential.user.accessToken;
      localStorage.setItem('accessToken', accessToken);
      return Promise.resolve();
    } catch (error) {
      throw new Error(error.message);
    }
  },

  logout: () => {
    localStorage.removeItem('accessToken');
    return Promise.resolve();
  },

  checkAuth: () =>
    localStorage.getItem('accessToken') ? Promise.resolve() : Promise.reject(),

  checkError: error => {
    if (error.status === 401 || error.status === 403) {
      localStorage.removeItem('accessToken');
      return Promise.reject();
    }
    return Promise.resolve();
  },

  getPermissions: () => Promise.resolve(),
};

export default authProvider;
