const postInstagramId = async instagramId => {
  try {
    const response = await fetch(`https://motiver.kr/snslink`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        snsLink: `https://www.instagram.com/${instagramId}`,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error('서버로부터 응답을 받는데 실패했습니다.');
  }
};

export default postInstagramId;
