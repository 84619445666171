import React from 'react';
import { Datagrid, DateField, List, TextField } from 'react-admin';

const PreApplyLists = props => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source='id' label='ID' />
        <TextField source='instagramId' label='인스타그램 아이디' />
        <DateField source='submissionTime' label='제출 시간' showTime />
      </Datagrid>
    </List>
  );
};

export default PreApplyLists;
