import React from 'react';
import { Admin, Resource } from 'react-admin';
import authProvider from '../auth/authProvider';
import { MyMenu } from '../components/Admin/MyMenu';
import dataProvider from '../dataProvider/index';
import AdminDashboard from '../pages/Admin/AdminDashboard';
import AdminLoginPage from '../pages/Admin/AdminLoginPage';
import { ChatLists } from '../pages/Admin/ChatLists';
import ChatRoomLists from '../pages/Admin/ChatRoomLists';
import PreApplyLists from '../pages/Admin/PreApplyLists';
import UserLists from '../pages/Admin/UserLists';
import UserPhotoLists from '../pages/Admin/UserPhotoLists';

const AdminRoutes = () => (
  <Admin
    loginPage={AdminLoginPage}
    authProvider={authProvider}
    dataProvider={dataProvider}
    dashboard={AdminDashboard}
    menu={MyMenu}
  >
    <Resource name='users' list={UserLists} />
    <Resource name='photos' list={UserPhotoLists} />
    <Resource name='pre-applies' list={PreApplyLists} />
    <Resource name='chat' list={ChatLists} />
    <Resource name='chatRooms' list={ChatRoomLists} />
  </Admin>
);

export default AdminRoutes;
