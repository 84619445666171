import React from 'react';

const MainCopy = () => (
  <div className='mainCopy'>
    <span>함께 운동할 친구,</span>
    <br />
    <span>모티브에서 찾아요!</span>
  </div>
);

export default MainCopy;
